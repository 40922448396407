<template>
    <div class="px-3 py-2">
        <b-sidebar aria-labelledby="sidebar-no-header-title" width="700px" no-header id="add_new_account" backdrop style="direction:ltr" right :title="$parent.lang.add_new_account" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" :style="`direction:`+$parent.lang.dir">
                    <div style="width:150px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span style="font-size:.8em;">{{pagename}}</span>
                    </div>
                    <div @click="hide" id="dhideExp" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>{{$parent.lang.close}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="px-3 py-2" :style="`direction:`+$parent.lang.dir">
                    <v-form ref="apform">
                        <v-row>
                            <v-col cols="12" md="2" sm="12">
                                <label>{{$parent.lang.classid}}</label>
                                <b-form-input class="inborder"
                                    :label="$parent.lang.classid"
                                    v-model="current.classid"
                                    @change="getName(current.classid)"
                                    >{{ current.classid }}</b-form-input>
                            </v-col>
                            <v-col cols="12" md="5" sm="12">
                                <label>{{$parent.lang.account_name_ar}}</label>
                                <b-form-input class="inborder"
                                    :label="$parent.lang.account_name_ar"
                                    v-model="current.namear"
                                    >{{ current.namear }}</b-form-input>
                            </v-col>
                            <v-col cols="12" md="5" sm="12">
                                <label>{{$parent.lang.account_name_en}}</label>
                                <b-form-input class="inborder"
                                    :label="$parent.lang.account_name_en"
                                    v-model="current.nameen"
                                    >{{ current.nameen }}</b-form-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="2" sm="12">
                                <label style="font-size:.8em">{{$parent.lang.mainaccount}}</label>
                                <b-form-input class="inborder"
                                    :label="$parent.lang.mainaccount"
                                    v-model="current.parentid"
                                    @change="getMain(current.parentid)"
                                    >{{ current.parentid }}</b-form-input>
                                
                            </v-col>
                            <v-col cols="12" md="5" sm="12">
                                <label>{{$parent.lang.mainaccount}}</label>
                                <b-form-input class="inborder"
                                    :label="$parent.lang.mainaccount"
                                    v-model="current.parent_name"
                                    disabled
                                    >{{ current.parent_name }}</b-form-input>
                            </v-col>
                            <v-col cols="12" md="5" sm="12">
                                <label>{{$parent.lang.account_type}}</label>
                                <b-input-group>
                                <b-form-select class="selborder"
                                    :label="$parent.lang.account_type"
                                    v-model="current.account_type"
                                    :options="account_types"
                                    >{{ current.account_type }}</b-form-select>
                                    <b-input-group-addon :style="`margin-`+$parent.lang.algin+`:-20px;margin-top:5px;`">
                                        <i class="fas fa-arrow-down"></i>
                                    </b-input-group-addon>
                                </b-input-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4" sm="12">
                                <label>{{$parent.lang.accoutn_option}}</label>
                                <b-input-group>
                                <b-form-select class="selborder"
                                    :label="$parent.lang.type"
                                    v-model="current.type"
                                    :options="accoutn_option"
                                    >{{ current.type }}</b-form-select>
                                    <b-input-group-addon :style="`margin-`+$parent.lang.algin+`:-20px;margin-top:5px;`">
                                        <i class="fas fa-arrow-down"></i>
                                    </b-input-group-addon>
                                </b-input-group>
                            </v-col>
                            <v-col cols="12" md="4" sm="12">
                                <label>{{$parent.lang.default_type}}</label>
                                <b-input-group>
                                <b-form-select class="selborder"
                                    :label="$parent.lang.default_type"
                                    v-model="current.default"
                                    :options="default_type"
                                    >{{ current.default }}</b-form-select>
                                    <b-input-group-addon :style="`margin-`+$parent.lang.algin+`:-20px;margin-top:5px;`">
                                        <i class="fas fa-arrow-down"></i>
                                    </b-input-group-addon>
                                </b-input-group>
                            </v-col>
                            <v-col cols="12" md="4" sm="12">
                                <label>{{$parent.lang.account_integrated}}</label>
                                <b-input-group>
                                <b-form-select class="selborder"
                                    :label="$parent.lang.account_integrated"
                                    v-model="current.integrated"
                                    :options="integratedOptions"
                                    ></b-form-select>
                                    <b-input-group-addon :style="`margin-`+$parent.lang.algin+`:-20px;margin-top:5px;`">
                                        <i class="fas fa-arrow-down"></i>
                                    </b-input-group-addon>
                                </b-input-group>
                            </v-col>
                            <v-col cols="12" md="4" sm="12">
                                <label>{{$parent.lang.integrated_with_expenses}}</label>
                                <b-input-group>
                                <b-form-select class="selborder"
                                    :label="$parent.lang.integrated_with_expenses"
                                    v-model="current.expenses"
                                    :options="expenses_type"
                                    ></b-form-select>
                                    <b-input-group-addon :style="`margin-`+$parent.lang.algin+`:-20px;margin-top:5px;`">
                                        <i class="fas fa-arrow-down"></i>
                                    </b-input-group-addon>
                                </b-input-group>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
            </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" variant="success" @click="addit()" class="ma-2" style="width:100px;">{{btnname}}</b-button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default{
    data() {
        return {
            pagename: this.$parent.lang.add_new_account,
            current: {
                id: 0,
                namear: '',
                nameen: '',
                classid: '',
                parentid: 0,
                parent_name: this.$parent.lang.mainaccount,
                account_type: 1,
                type: 1,
                default: 1,
                integrated: 1,
                expenses: 0,
            },
            account_types: [
                {text: 'ميزانية', value: 1},
                {text: 'أرباح وخسائر', value: 2},
                {text: 'أيرادات', value: 3},
                {text: 'أخرى', value: 4},
            ],
            accoutn_option: [
                {text: 'تحليلي', value: 2},
                {text: 'رئيسي', value: 1},
            ],
            default_type: [
                {text: 'مدين', value: 1},
                {text: 'دائن', value: 2},
            ],
            expenses_type: [
                {text: '', value: 0},
                {text: 'مصاريف', value: 1},
                {text: 'مصاريف حكومية', value: 2},
                {text: 'مصاريف البدلات', value: 3},
            ],
            integratedOptions:[
                
            ],

            btnname: this.$parent.lang.add,
        }
    },
    created() {
        this.getIntegrated();
    },
    methods: {
        resetvalues(){
            this.current = {
                id: 0,
                namear: '',
                nameen: '',
                classid: '',
                parentid: 0,
                parent_name: this.$parent.lang.mainaccount,
                account_type: 1,
                type: 1,
                default: 1,
                integrated: 1
            }
        },
        getIntegrated(){
            const post = new FormData();
            post.append("type","getIntegOptions")
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.$parent.lang.langname)
            post.append("data[classid]",0);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.integratedOptions = [];
                for(let i=0;i<res.results.data.length;i++){
                    let text2 = this.$parent.lang.langname == "ar" ? res.results.data[i].namear : res.results.data[i].nameen;
                    let value2 = res.results.data[i].id
                    this.integratedOptions.push({text: text2, value: value2})
                }
            })
        },
        getAllInte(){
            const post = new FormData();
            post.append("type","getIntegOptions")
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.$parent.lang.langname);
            post.append("data[classid]",'all');

            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.integratedOptions = [];
                for(let i=0;i<res.results.data.length;i++){
                    let text2 = this.$parent.lang.langname == "ar" ? res.results.data[i].namear : res.results.data[i].nameen;
                    let value2 = res.results.data[i].id
                    this.integratedOptions.push({text: text2, value: value2})
                }
            })
        },
        getName(classid){
            const post = new FormData();
            post.append("type","getClassname")
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.$parent.lang.langname);
            post.append("data[classid]",classid);

            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                if(res.results.data.length == 0) return false;
                this.current = {
                    id: res.results.data.id,
                    namear: res.results.data.namear,
                    nameen: res.results.data.nameen,
                    classid: res.results.data.classid,
                    parentid: res.results.data.parentid,
                    parent_name: '',
                    account_type: res.results.data.account_type,
                    type: res.results.data.type,
                    default: res.results.data.default,
                    integrated: res.results.data.integrated
                }
                this.getMain(res.results.data.parentid);
            })
        },
        getMain(classid){
            const post = new FormData();
            post.append("type","getClassname")
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.$parent.lang.langname);
            post.append("data[classid]",classid);

            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                this.current.parent_name = res.results.data.name;
                // this.getMain(res.results.data.parentid);
            })
        },
        addit() {
            let vald = true;
            let message = '';
            if(this.current.classid == ''){
                vald = false;
                message = 'رقم الحساب مطلوب';
            }
            if(this.current.namear == ''){
                if(this.current.nameen != ''){
                    this.current.namear = this.current.nameen;
                }else{
                    vald = false;
                    message = 'اسم الحساب العربي مطلوب';
                }
            }
            if(this.current.nameen == ''){
                if(this.current.namear != ''){
                    this.current.nameen = this.current.namear;
                }else{
                    vald = false;
                    message = 'اسم الحساب الانجليزي مطلوب';
                }
            }
            if(this.current.parentid == '') this.current.parentid = 0;
            if(this.current.account_type == '') this.current.account_type = 1;
            if(this.current.type == '') this.current.type = 1;
            if(this.current.integrated == '') this.current.integrated = 1;
            if(this.current.default == '') this.current.default = 1;
            if(this.current.default == '') this.current.default = 1;
            if(vald == false){
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append("type","addEditAccount");
            // console.log(this.$parent.lang.langname);
            post.append("auth",this.$cookies.get(this.$COOKIEPhase))
            post.append("data[lang]",this.$parent.lang.langname)
            post.append("data[namear]",this.current.namear);
            post.append("data[nameen]",this.current.nameen);
            post.append("data[classid]",this.current.classid);
            post.append("data[parentid]",this.current.parentid);
            post.append("data[account_type]",this.current.account_type);
            post.append("data[type]",this.current.type);
            post.append("data[integrated]",this.current.integrated);
            post.append("data[default]",this.current.default);
            post.append("data[id]",this.current.id);
            post.append("data[expenses]",this.current.expenses);

            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => { 
                this.$parent.getReport(0);   
                const res = response.data;          
                // console.log(res);
                document.getElementById('dhideExp').click();
                let message = res.results.data;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
            })
        }
    },
}
</script>